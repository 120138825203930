import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import vission from "../assets/vission/vission.png";
import line from "../assets/vission/line.svg";
import missionHero from "../assets/vission/missionHero.png";
import mission1 from "../assets/vission/mission1.png";
import mission2 from "../assets/vission/mission2.png";
import ellipse from "../assets/vission/ellipse.svg";
import mission from "../assets/vission/mission.svg";
import story from "../assets/vission/story.png";

import "../index.css";

const VissionAndMission = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: {
            xs: "200px", // small screens
            sm: "300px", // small to medium screens
            md: "300px", // medium screens
            lg: "300px", // large screens
            xl: "300px", // extra large screens
          },
          backgroundImage: `url(${missionHero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></Box>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            // justifyContent: "center",
            // alignItems: "center",
            // gap: { xs: "24px", sm: "32px", md: "44px" },
            width: "100%",
            padding: { xs: "16px", sm: "24px", md: "32px" },
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Typography
              sx={{
                color: "primary.main",

                textAlign: {
                  xs: "center",
                  md: "left",
                },
                // margin: { xs: "auto 0" },
              }}
              className="custom-head"
            >
              OUR VISION
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginLeft: { xs: 0, md: "5rem" },
                justifyContent: { xs: "center", md: "flex-start" },
                // marginTop: "-1rem",
              }}
            >
              <img src={line} alt="line" />
            </Box>

            <Box>
              <Box sx={{ position: "absolute" }}>
                <img src={ellipse} alt="ellipse" />
              </Box>
              <Typography
                sx={{
                  textAlign: { xs: "center", md: "left" }, // Center text on mobile screens
                  width: {
                    xs: "90%", // 90% width on extra small screens
                    sm: "80%", // 80% width on small screens
                    md: "70%", // 70% width on medium screens
                    lg: "90%", // 60% width on large screens
                  }, // Responsive width
                  position: "relative",
                  paddingLeft: "30px",
                  paddingTop: "30px",
                  width: { md: "70%" },
                }}
                className="custom-text"
              >
                At Our Company, We understand that each student seeking to study
                abroad has unique requirements, and thus, their journey to
                finding the right university must also be tailored to their
                specific needs. Unlock your full potential by studying in the
                world’s leading English-speaking nations with the assistance of
                our outstanding overseas education consultants.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%", // Ensure the Box takes full width
              maxWidth: "477px", // Limit max width
            }}
          >
            <img
              src={vission}
              alt="hero"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: { md: "flex" },
            marginY: { xs: "1rem", md: "3rem" },
          }}
        >
          <Box
            sx={{
              width: { md: "20%" }, // 60% width on medium and larger screens
              ml: { md: "16px" }, // Margin left on medium and larger screens
            }}
          >
            <Typography
              sx={{
                color: "primary.main",

                textAlign: {
                  xs: "center",
                  md: "left",
                },
                // margin: { xs: "auto 0" },
              }}
              className="custom-head"
            >
              OUR MISSION
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginLeft: { xs: 0, md: "5rem" },
                justifyContent: { xs: "center", md: "flex-start" },
                // marginTop: "-1rem",
              }}
            >
              <img src={line} alt="line" />
            </Box>
          </Box>
          <Box
            sx={{
              width: { md: "80%" }, // 60% width on medium and larger screens
              ml: { md: "16px" }, // Margin left on medium and larger screens
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%", // Ensure the Box takes full width
              }}
            >
              <Box sx={{ position: "absolute", top: 0, left: 0 }}>
                <img src={ellipse} alt="ellipse" />
              </Box>
              <Typography
                sx={{
                  textAlign: { xs: "center", md: "left" }, // Center text on mobile screens
                  width: {
                    xs: "90%", // 90% width on extra small screens
                    sm: "80%", // 80% width on small screens
                    md: "70%", // 70% width on medium screens
                    lg: "90%", // 90% width on large screens
                  }, // Responsive width
                  position: "relative",
                  paddingLeft: "30px",
                  paddingTop: "30px",
                }}
                className="custom-text"
              >
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "block", md: "flex" }, // Stack vertically on small screens, flex horizontally on medium and larger screens

            marginY: { xs: "1rem", md: "2rem" },
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center", // Center image horizontally in the flex container
              padding: { xs: "1rem", md: "0" }, // Add padding on small screens
            }}
          >
            <img
              src={mission1}
              alt="mission1"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Box>

          <Box
            sx={{
              flex: 1,
              border: "8px solid #02007B",
              background: "rgba(255, 241, 241, 0.45)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <img src={mission} alt="mission" style={{ width: "5rem" }} />
            <Typography
              sx={{
                textAlign: "center", // Center text in the column layout
                width: { xs: "100%", sm: "20rem" }, // Full width on small screens, fixed width on larger screens
                margin: { xs: "0 auto", md: "0" }, // Center text horizontally on small screens
              }}
              className="custom-text"
            >
              Sed dvsdv ut perspiciatis unde omnis iste natus error sit
              voluptatem accusantium doloremque laudantium, totam rem aperiam,
              eaque ipsa quae ab illo inventore veritatis et quasi architecto
              beatae vitae dicta sunt explicabo. Nemo enim ipsam.
            </Typography>
          </Box>

          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              padding: { xs: "1rem", md: "0" },
            }}
          >
            <img
              src={mission2}
              alt="mission2"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },

            width: "100%",
            padding: { xs: "16px", sm: "24px", md: "32px" },
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={story}
              alt="story"
              style={{ width: "80%", height: "auto" }}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Typography
              sx={{
                color: "primary.main",

                textAlign: {
                  xs: "center",
                  md: "left",
                },
                // margin: { xs: "auto 0" },
              }}
              className="custom-head"
            >
              OUR STORY
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginLeft: { xs: 0, md: "5rem" },
                justifyContent: { xs: "center", md: "flex-start" },
                // marginTop: "-1rem",
              }}
            >
              <img src={line} alt="line" />
            </Box>

            <Box>
              <Box sx={{ position: "absolute" }}>
                <img src={ellipse} alt="ellipse" />
              </Box>
              <Typography
                sx={{
                  // width: { xs: "100%", sm: "24rem", md: "28rem" },

                  textAlign: { xs: "center", md: "left" }, // Center text on mobile screens
                  width: {
                    xs: "90%", // 90% width on extra small screens
                    sm: "80%", // 80% width on small screens
                    md: "70%", // 70% width on medium screens
                    lg: "90%", // 60% width on large screens
                  }, // Responsive width
                  position: "relative",
                  paddingLeft: "30px",
                  paddingTop: "30px",
                }}
                className="custom-text"
              >
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam. Nemo enim ipsam
                voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed
                quia consequuntur magni dolores eos qui ratione voluptatem sequi
                nesciunt.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default VissionAndMission;
