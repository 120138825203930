import { Box, Container, Grid } from "@mui/material";
import React from "react";
import group from "../assets/Blog/group.png";
import hero from "../assets/successstories/hero.jpg";
import image1 from "../assets/successstories/image1.png";

const SuccessStories = () => {
  const data = [
    {
      photo: image1,
      altText: "image1",
    },
    {
      photo: image1,
      altText: "image1",
    },
    {
      photo: image1,
      altText: "image1",
    },
    {
      photo: image1,
      altText: "image1",
    },
    {
      photo: image1,
      altText: "image1",
    },
    {
      photo: image1,
      altText: "image1",
    },
    {
      photo: image1,
      altText: "image1",
    },
    {
      photo: image1,
      altText: "image1",
    },
    {
      photo: image1,
      altText: "image1",
    },
    {
      photo: image1,
      altText: "image1",
    },
    {
      photo: image1,
      altText: "image1",
    },
    {
      photo: image1,
      altText: "image1",
    },
  ];
  return (
    <>
      {" "}
      <Box
        sx={{
          width: "100%",
          height: {
            xs: "200px", // small screens
            sm: "300px", // small to medium screens
            md: "300px", // medium screens
            lg: "300px", // large screens
            xl: "300px", // extra large screens
          },
          backgroundImage: `url(${hero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Container>
        <Grid container spacing={1} marginY="2rem">
          {data.map((item) => (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box
                component="img"
                src={item.photo}
                alt={item.altText}
                sx={{
                  width: "100%",
                  maxWidth: "500px",
                  height: "auto",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default SuccessStories;
