import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import hero from "../assets/hero.png";
import homemap from "../assets/homemap.svg";
import twobars from "../assets/twobars.svg";
import HomeCards from "../Components/HomeCards";
import ConsultCarousel from "../Components/ConsultCarousel";
import FloatingButton from "../Components/Appointment/FloatingButton";
import Destination from "../Components/Destination/Destination";
import Accrediation from "../Components/Accrediation/Accrediation";

import "../index.css";
import Experience from "../Components/Experience";

const Home = () => {
  return (
    <>
      <FloatingButton />
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            justifyContent: "center",
            alignItems: "center",
            gap: { xs: "24px", sm: "32px", md: "44px" },
            width: "100%",
            padding: { xs: "16px", sm: "24px", md: "32px" },
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: { xs: "24px", sm: "32px", md: "44px" }, // Responsive gap
              textAlign: { xs: "center", md: "left" }, // Center text on mobile screens
            }}
          >
            <Typography
              sx={{
                color: "primary.main",
              }}
              className="custom-head"
            >
              Start Your Journey <br /> to{" "}
              <Typography
                component="span"
                sx={{
                  color: "secondary.main",
                }}
                className="custom-head"
              >
                Study Abroad
              </Typography>
            </Typography>
            <Typography
              sx={{
                width: { xs: "100%", sm: "24rem", md: "28rem" }, // Responsive width

                textAlign: { xs: "center", md: "left" }, // Center text on mobile screens
              }}
              className="custom-text"
            >
              Evolve your critical thinking, adaptability, and communication
              skills with international exposure. Choose your university abroad.
            </Typography>
            <Button
              variant="contained"
              size="large"
              sx={{
                fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "24px" }, // Responsive font size
                fontFamily: "RocknRoll One",
                fontWeight: "400",
                borderRadius: "8px",
                textTransform: "capitalize",
              }}
            >
              Apply Now
            </Button>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%", // Ensure the Box takes full width
              maxWidth: "477px", // Limit max width
            }}
          >
            <img
              src={hero}
              alt="hero"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            marginTop: "30px",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            {" "}
            <img src={homemap} alt="hero" />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "20px",
            }}
          >
            <Typography sx={{ color: "primary.main" }} className="custom-head">
              From Career Counselling to University Admissions
            </Typography>
            <Typography className="custom-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Typography>
            <Button variant="contained" size="large" className="home-button">
              Get Started
            </Button>
            <Box
              sx={{
                //display: "flex",
                //justifyContent: "flex-end",
                marginLeft: "auto",
              }}
            >
              <img src={twobars} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginY: "30px",
          }}
        >
          <Typography
            sx={{
              color: "primary.main",
            }}
            className="custom-head"
          >
            The{" "}
            <Typography
              component="span"
              sx={{
                color: "secondary.main",
              }}
              className="custom-head"
            >
              Career Counseling{" "}
            </Typography>
            Life Cycle
          </Typography>
        </Box>
        <HomeCards />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginY: "42px",
          }}
        >
          <img src={twobars} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Stack on mobile, row on desktop
            width: "100%", // Ensure the container takes full width
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // This justifies the content around the Box
              alignItems: "flex-start", //
              gap: "44px",
            }}
          >
            <Typography
              className="custom-head"
              sx={{
                color: "primary.main",
              }}
            >
              BEST CAREER OPPORTUNITIES,
              <br />
              BEST JOBS, BEST LIFESTYLE !!
            </Typography>
            <Typography className="custom-text">
              Study Abroad Journey: With you at every step of your study abroad
              journey <br /> Get personalised, friendly, honest guidance for
              free
            </Typography>
            <Button variant="outlined" size="medium" className="consult-button">
              Get Free Consultation
            </Button>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%", // Adjust as needed
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ConsultCarousel />
            </Box>
          </Box>
        </Box>

        <Destination />

        <Experience />
        <Accrediation />
      </Container>
    </>
  );
};

export default Home;
