import React, { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import "./Navbar.css";
import NavDrawer from "./NavDrawer";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [who, setWho] = useState(null);

  const handleStudyTab = (event) => {
    setAnchorEl(event.target);
  };

  const handleWhoTab = (event) => {
    setWho(event.target);
  };

  const handleStudyClose = () => {
    setAnchorEl(null);
  };

  const handleWhoClose = () => {
    setWho(null);
  };
  const handleSignInClick = () => {
    navigate("/signin"); // Navigate to the Sign In page
  };
  const handleSignUpClick = () => {
    navigate("/signup"); // Navigate to the Sign In page
  };

  const tabs = [
    { id: 0, label: "Home", route: "/" },
    {
      id: 1,
      label: (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          Who we are <KeyboardArrowDownIcon />
        </Box>
      ),
      onClick: handleWhoTab,
    },
    {
      id: 2,
      label: (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          Study Abroad <KeyboardArrowDownIcon />
        </Box>
      ),
      onClick: handleStudyTab,
    },
    { id: 3, label: "Success Stories", route: "/success-stories" },
    { id: 4, label: "Blog", route: "/blog" },
    { id: 5, label: "Contact", route: "/contact" },
  ];

  return (
    <AppBar position="sticky" color="white">
      <Toolbar sx={{ height: "100px" }}>
        <img src={logo} alt="logo" />
        {isMatch ? (
          <NavDrawer tabs={tabs} />
        ) : (
          <>
            <Tabs
              value={value}
              onChange={(event, newValue) => setValue(newValue)}
              sx={{
                "& .MuiTabs-indicator": {
                  transition: "none !important",
                },
                margin: "auto",
                "& .MuiTab-root": {
                  fontFamily: "RocknRoll One, sans-serif",
                  fontSize: "1.00rem",
                  fontWeight: 400,
                  textTransform: "capitalize",
                  color: "#000F45",
                },
                // "& .Mui-selected": {
                //   color: "#000F45",
                // },
              }}
              indicatorColor="secondary"
              TabIndicatorProps={{
                sx: { display: "none" },
              }}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  value={tab.id}
                  label={tab.label}
                  component={tab.route ? Link : "div"}
                  to={tab.route || "#"}
                  onClick={tab.onClick}
                />
              ))}
            </Tabs>
            <Button
              variant="contained"
              size="medium"
              className="nav-button"
              onClick={handleSignUpClick}
            >
              Sign up
            </Button>
            <Button
              variant="outlined"
              size="medium"
              className="nav-button"
              sx={{ marginLeft: "10px" }}
              onClick={handleSignInClick}
            >
              Sign in
            </Button>
          </>
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleStudyClose}
          sx={{ width: "17rem" }}
        >
          <MenuItem
            component={Link}
            to="/study-in-uk"
            className="custom-menu-item"
          >
            Study In UK
          </MenuItem>
          <MenuItem
            component={Link}
            to="/study-in-australia"
            className="custom-menu-item"
          >
            Study In Australia
          </MenuItem>
          <MenuItem
            component={Link}
            to="/study-in-canada"
            className="custom-menu-item"
          >
            Study In Canada
          </MenuItem>
          <MenuItem
            component={Link}
            to="/study-in-usa"
            className="custom-menu-item"
          >
            Study In USA
          </MenuItem>
          <MenuItem
            component={Link}
            to="/study-in-new-zealand"
            className="custom-menu-item"
          >
            Study In New Zealand
          </MenuItem>
        </Menu>
        <Menu anchorEl={who} open={Boolean(who)} onClose={handleWhoClose}>
          <MenuItem
            component={Link}
            to="/about-us"
            className="custom-menu-item"
          >
            About Us
          </MenuItem>
          <MenuItem
            component={Link}
            to="/vision-mission"
            className="custom-menu-item"
          >
            Our Vision & Mission
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
