import React from "react";
import { Grid, Typography, Box } from "@mui/material";

import accomodation from "../../assets/aboutus/accomodation.svg";
import uni from "../../assets/aboutus/uni.svg";
import visa from "../../assets/aboutus/visa.svg";

import dep from "../../assets/aboutus/dep.svg";

import "../../index.css";

const AboutUsCards = () => {
  const data = [
    {
      id: 1,
      imgSrc: accomodation,
      altText: "img",
      title: "Accomodation",
      description:
        "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur",
    },
    {
      id: 2,
      imgSrc: uni,
      altText: "profile",
      title: "Changing Universities",
      description:
        "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur",
    },
    {
      id: 3,
      imgSrc: visa,
      altText: "note",
      title: "Visa Consultancy",
      description:
        "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur",
    },
    {
      id: 4,
      imgSrc: dep,
      altText: "plus",
      title: "Pre-Departure",
      description:
        "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur",
    },
  ];
  const Cards = ({ imgSrc, altText, title, description }) => (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "auto",
          height: "auto",
          bgcolor: "#F4F4F4",
          borderRadius: "20px",
          boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.25)",
          margin: "10px",
          textAlign: "center",
          padding: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <img src={imgSrc} alt={altText} sx={{ width: "69px" }} />
          <Typography className="card-title">{title}</Typography>
          <Typography className="card-description">{description}</Typography>
        </Box>
      </Box>
    </>
  );
  return (
    <>
      <Grid container spacing={1}>
        {data.map((item) => (
          <Grid item xs={12} sm={6} md={3} key={item.id}>
            <Cards
              imgSrc={item.imgSrc}
              altText={item.altText}
              title={item.title}
              description={item.description}
              className="custom-card-background"
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default AboutUsCards;
