import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#03008A",
      light: "#F1F1F1",
    },
    secondary: {
      main: "#FF6BA0",
    },
    nav: {
      main: "#000F45",
    },
    white: {
      main: "#FFFFFF",
    },
  },
  // typography: {
  fontFamily: ["Philosopher", "RocknRoll One", "sans-serif"].join(","),
  //   h1: {
  //     fontSize: "3rem",
  //     fontWeight: 600,
  //   },
  //   h2: {
  //     fontSize: "1.75rem",
  //     fontWeight: 600,
  //   },
  //   h3: {
  //     fontSize: "1.5rem",
  //     fontWeight: 600,
  //   },
  // },
});

export default theme;
