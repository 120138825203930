import React from "react";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Box, Grid, Typography, Button } from "@mui/material";
import "../../index.css";

const ContactUsForm = () => {
  return (
    <>
      <Paper
        elevation={5}
        sx={{
          background: "#EBF0FF",
          padding: "40px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography className="contactus-fields-head">
                First Name
              </Typography>
              <TextField
                variant="outlined"
                placeholder="First Name"
                type="text"
                className="form-fields"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography className="contactus-fields-head">
                Last Name
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Last Name"
                type="text"
                className="form-fields"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography className="contactus-fields-head">
                Email Id
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Your email"
                type="text"
                className="form-fields"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography className="contactus-fields-head">
                Phone Number
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Contact Number"
                type="phone"
                className="form-fields"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography className="contactus-fields-head">Country</Typography>
              <TextField
                variant="outlined"
                placeholder="Your Country"
                type="text"
                className="form-fields"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography className="contactus-fields-head">Message</Typography>
              <TextField
                multiline
                variant="outlined"
                placeholder="Your Message"
                type="text"
                className="form-fields"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Button variant="contained" className="contactus-button">
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ContactUsForm;
