import React from "react";
import TextField from "@mui/material/TextField";
import {
  Box,
  Grid,
  Typography,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "./Appointment.css";

const Appointment = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm")); // Use full screen on small devices

  const destinations = [
    { value: "Australia", label: "Australia" },
    { value: "Canada", label: "Canada" },
    { value: "UK", label: "UK" },
    { value: "USA", label: "USA" },
    { value: "New Zealand", label: "New Zealand" },
  ];

  const intake = [
    { value: "Australia", label: "Australia" },
    { value: "Canada", label: "Canada" },
    { value: "UK", label: "UK" },
    { value: "USA", label: "USA" },
    { value: "New Zealand", label: "New Zealand" },
  ];

  const year = [
    { value: "Australia", label: "Australia" },
    { value: "Canada", label: "Canada" },
    { value: "UK", label: "UK" },
    { value: "USA", label: "USA" },
    { value: "New Zealand", label: "New Zealand" },
  ];

  const office = [
    { value: "Australia", label: "Australia" },
    { value: "Canada", label: "Canada" },
    { value: "UK", label: "UK" },
    { value: "USA", label: "USA" },
    { value: "New Zealand", label: "New Zealand" },
  ];

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          // Custom styles for the dialog paper
          backgroundColor: "primary.light",
          //color: "primary.contrastText",
          padding: 2,
          borderRadius: 10,
        },
      }}
    >
      <DialogTitle className="appointment-head">
        BOOK AN APPOINTMENT
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Box>
              <Typography className="appointment-fields-head">
                First Name
              </Typography>
              <TextField
                variant="outlined"
                placeholder="First Name"
                type="text"
                className="appointment-fields"
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box>
              <Typography className="appointment-fields-head">
                Last Name
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Last Name"
                type="text"
                className="appointment-fields"
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box>
              <Typography className="appointment-fields-head">
                Email Id
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Your email"
                type="text"
                className="appointment-fields"
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box>
              <Typography className="appointment-fields-head">
                Contact Number
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Contact Number"
                type="phone"
                className="appointment-fields"
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box>
              <Typography className="appointment-fields-head">
                Subject (Optional)
              </Typography>
              <TextField
                id="outlined-multiline-flexible"
                variant="outlined"
                placeholder="Your Subject"
                type="text"
                multiline
                className="appointment-fields"
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box>
              <Typography className="appointment-fields-head">
                Study Destination
              </Typography>
              <TextField
                select
                defaultValue="UK"
                className="appointment-fields"
              >
                {destinations.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box>
              <Typography className="appointment-fields-head">
                Intake
              </Typography>
              <TextField
                select
                defaultValue="UK"
                className="appointment-fields"
              >
                {intake.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box>
              <Typography className="appointment-fields-head">
                Study Year
              </Typography>
              <TextField
                select
                defaultValue="UK"
                className="appointment-fields"
              >
                {year.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box>
              <Typography className="appointment-fields-head">
                Nearest Office
              </Typography>
              <TextField
                select
                defaultValue="UK"
                className="appointment-fields"
              >
                {office.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box></Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box>
              <Button variant="contained" className="appointment-button">
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default Appointment;
