import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import background from "../assets/contact/background.png";
import pin from "../assets/contact/pin.svg";
import call from "../assets/contact/call.svg";
import mail from "../assets/contact/mail.svg";
import "../index.css";
import ContactUsForm from "../Components/ContactUs/ContactUsForm";
const Contact = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: {
            xs: "200px", // small screens
            sm: "300px", // small to medium screens
            md: "300px", // medium screens
            lg: "300px", // large screens
            xl: "300px", // extra large screens
          },
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "right",
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column", // Arrange children in a column
              justifyContent: "center",
              height: {
                xs: "200px", // small screens
                sm: "300px", // small to medium screens
                md: "300px", // medium screens
                lg: "300px", // large screens
                xl: "300px", // extra large screens
              },
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  fontSize: {
                    xs: "1.2rem", // Extra Small Devices (phones in portrait mode)
                    sm: "1.5rem", // Small Devices (phones in landscape mode, smaller tablets)
                    md: "3rem", // Medium Devices (tablets, small desktops)
                    lg: "4rem", // Large Devices (desktops, larger screens)
                  },
                  fontWeight: 700,
                  textAlign: {
                    xs: "center", // Center align on extra small screens
                    md: "left", // Left align on medium screens and up
                  }, // Responsive text alignment
                }}
              >
                Contact Us
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  fontFamily: "RocknRoll One",
                  fontSize: { xs: "1rem", sm: "1.25rem" }, // Responsive font size
                  textAlign: { xs: "center", md: "left" }, // Center text on mobile screens
                  width: {
                    xs: "90%", // 90% width on extra small screens
                    sm: "80%", // 80% width on small screens
                    md: "70%", // 70% width on medium screens
                    lg: "60%", // 60% width on large screens
                  }, // Responsive width
                }}
              >
                Together, We can create something all inspirational.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box
          sx={{
            display: { md: "flex" },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                color: "primary.main",
                fontSize: {
                  xs: "1.2rem", // Extra Small Devices (phones in portrait mode)
                  sm: "1.5rem", // Small Devices (phones in landscape mode, smaller tablets)
                  md: "1.8rem", // Medium Devices (tablets, small desktops)
                  lg: "2rem", // Large Devices (desktops, larger screens)
                },
                fontWeight: 600,
                textAlign: "center",
                marginY: "20px",
              }}
            >
              Contact Information
            </Typography>
            <Box
              sx={{
                display: "flex",
                padding: "20px",
                alignItems: "center",
              }}
            >
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <img src={pin} alt="" />
                    <Box sx={{ flexDirection: "column" }}>
                      <Typography
                        className="contact-listItems"
                        sx={{
                          marginBottom: "11px",
                          fontWeight: 700,
                          fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
                        }}
                      >
                        Location{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "0.875rem",
                            sm: "1rem",
                            md: "1.125rem",
                          },
                          width: "70%",
                        }}
                      >
                        um consequuntur distinctio aut dolores id dolores
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <img src={call} alt="" />
                    <Box sx={{ flexDirection: "column" }}>
                      <Typography
                        className="contact-listItems"
                        sx={{
                          marginBottom: "11px",
                          fontWeight: 700,
                          fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
                        }}
                      >
                        Call{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "0.875rem",
                            sm: "1rem",
                            md: "1.125rem",
                          },
                        }}
                      >
                        +91 8766554378 <br />
                        +91 5476384320
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <img src={mail} alt="" />
                    <Box sx={{ flexDirection: "column" }}>
                      <Typography
                        className="contact-listItems"
                        sx={{
                          marginBottom: "11px",
                          fontWeight: 700,
                          fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
                        }}
                      >
                        Email{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "0.875rem",
                            sm: "1rem",
                            md: "1.125rem",
                          },
                        }}
                      >
                        info@education.com
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              margin: "0 auto",
            }}
          >
            <Typography
              sx={{
                color: "primary.main",
                fontSize: {
                  xs: "1.2rem", // Extra Small Devices (phones in portrait mode)
                  sm: "1.5rem", // Small Devices (phones in landscape mode, smaller tablets)
                  md: "1.8rem", // Medium Devices (tablets, small desktops)
                  lg: "2rem", // Large Devices (desktops, larger screens)
                },
                fontWeight: 600,
                textAlign: "center",
                marginY: "20px",
              }}
            >
              Get In Touch With Us
            </Typography>
            <ContactUsForm />
          </Box>
        </Box>
        <Box sx={{ height: "30px" }}></Box>
      </Container>
    </>
  );
};

export default Contact;
