import React from "react";
import { Box, Container, Typography } from "@mui/material";
import aboutusHero from "../assets/aboutusHero.png";
import "../index.css";
import AboutUsCards from "../Components/AboutUs/AboutUsCards";
import Featured from "../Components/AboutUs/Featured";
import Experience from "../Components/Experience";
import person1 from "../assets/aboutus/aboutusPerson.png";
import person2 from "../assets/aboutus/aboutusPerson2.png";

const Aboutus = () => {
  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Column on mobile, row on larger screens
            justifyContent: "center",
            alignItems: "center", // Center items both vertically and horizontally
            gap: { xs: "24px", sm: "32px", md: "44px" }, // Responsive gap
            width: "100%", // Ensure the Box takes full width
            padding: { xs: "16px", sm: "24px", md: "32px" }, // Add responsive padding
            boxSizing: "border-box", // Include padding and border in the element's total width and height
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              //   justifyContent: "center",
              alignItems: "flex-start",
              gap: { xs: "24px", sm: "32px", md: "44px" }, // Responsive gap
              textAlign: { xs: "center", md: "left" }, // Center text on mobile screens
            }}
          >
            <Typography
              sx={{
                color: "primary.main",

                textAlign: {
                  xs: "center", // Center align on extra small screens
                  md: "left", // Left align on medium screens and up
                }, // Responsive text alignment
                margin: { xs: "auto 0" },
              }}
              className="custom-head"
            >
              About Us
            </Typography>
            <Typography
              sx={{
                fontFamily: "RocknRoll One",
                // width: { xs: "100%", sm: "24rem", md: "28rem" },
                fontSize: { xs: "1rem", sm: "1.25rem" }, // Responsive font size
                textAlign: { xs: "center", md: "left" }, // Center text on mobile screens
                width: {
                  xs: "90%", // 90% width on extra small screens
                  sm: "80%", // 80% width on small screens
                  md: "70%", // 70% width on medium screens
                  lg: "90%", // 60% width on large screens
                }, // Responsive width
              }}
            >
              At Our Company, We understand that each student seeking to study
              abroad has unique requirements, and thus, their journey to finding
              the right university must also be tailored to their specific
              needs. Unlock your full potential by studying in the world’s
              leading English-speaking nations with the assistance of our
              outstanding overseas education consultants.
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%", // Ensure the Box takes full width
              maxWidth: "477px", // Limit max width
            }}
          >
            <img
              src={aboutusHero}
              alt="hero"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Box>
      </Container>
      <Box
        sx={{
          background: "linear-gradient(to bottom, #FEDDDD, #FFFFFF)",
          padding: "2rem",
        }}
      >
        <Container>
          <Typography
            sx={{
              color: "primary.main",
              fontFamily: "Philosopher",
              textAlign: "center",
              fontStyle: "normal",

              lineHeight: "100%",
            }}
            className="custom-head"
          >
            Why Choose Us
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: "1rem", // Responsive font size for extra-small screens
                sm: "1.25rem", // Responsive font size for small screens
              },
              textAlign: "center",
              marginY: "1.5rem",
            }}
            className="custom-text"
          >
            We’ve created a range of ancillary services to make your study
            abroad journey less daunting, more accessible and total exciting.
            achieve your dreams with the most experienced team of counselors. we
            are sum of our experiences.
          </Typography>
          <AboutUsCards />
        </Container>
      </Box>
      <Container>
        {/* <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              flex: 1,

              display: "flex",
              justifyContent: "right",
            }}
          >
            <img src={person1} alt="person1" width={"500px"} />
          </Box>
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                sx={{
                  color: "primary.main",
                  fontSize: {
                    xs: "1.2rem", // Extra Small Devices (phones in portrait mode)
                    sm: "1.5rem", // Small Devices (phones in landscape mode, smaller tablets)
                    md: "1.8rem", // Medium Devices (tablets, small desktops)
                    lg: "2rem", // Large Devices (desktops, larger screens)
                  },
                }}
                className="career-head"
              >
                We Have Experience &{" "}
                <Typography
                  component="span"
                  sx={{
                    color: "secondary.main",
                  }}
                  className="career-head"
                >
                  Professional{" "}
                </Typography>
                Mentors
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "1rem", // Responsive font size for extra-small screens
                    sm: "1.25rem", // Responsive font size for small screens
                  },
                  textAlign: "center",
                  marginY: "1.5rem",
                  padding: "2rem",
                }}
                className="custom-text"
              >
                We are committed to your success. Our team of dedicated
                instructors and support staff is here to guide you.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexShrink: 0 }}>
              <img src={person2} width="540px" alt="group2" />
            </Box>
          </Box>
        </Box> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
              mb: { xs: 2, md: 0 },
            }}
          >
            <img
              src={person1}
              alt="person1"
              style={{ width: "100%", maxWidth: "500px" }}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
              px: 2,
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                sx={{
                  color: "primary.main",

                  textAlign: "center",
                }}
                className="custom-head"
              >
                We Have Experienced & <br />{" "}
                <Typography
                  component="span"
                  sx={{
                    color: "secondary.main",
                  }}
                  className="custom-head"
                >
                  Professional{" "}
                </Typography>
                Mentors
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "1rem", // Responsive font size for extra-small screens
                    sm: "1.25rem", // Responsive font size for small screens
                  },
                  textAlign: { xs: "center", md: "left" },
                  my: 2,
                  px: { xs: 1, sm: 2 },
                }}
                className="custom-text"
              >
                We are committed to your success. Our team of dedicated
                instructors and support staff is here to guide you.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-start" },
                flexShrink: 0,
                mt: 2,
              }}
            >
              <img
                src={person2}
                alt="group2"
                style={{ width: "100%", maxWidth: "540px" }}
              />
            </Box>
          </Box>
        </Box>

        <Experience />
        <Typography
          sx={{
            color: "primary.main",
            textAlign: "center",
          }}
          className="custom-head"
        >
          Featured Universities
        </Typography>
        <Featured />
      </Container>
    </>
  );
};

export default Aboutus;
