import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import counsel from "../assets/homecards/counsel.svg";
import adm from "../assets/homecards/adm.svg";
import app from "../assets/homecards/app.svg";
import doc from "../assets/homecards/doc.svg";
import letter from "../assets/homecards/letter.svg";
import visa from "../assets/homecards/visa.svg";
import line from "../assets/homecards/line.svg";
import "../index.css";

const HomeCards = () => {
  const data = [
    {
      id: 1,
      step: "STEP 1",
      imgSrc: counsel,
      altText: "img",
      title: "Counselling & Screening",
      bar: line,
      description:
        "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur",
    },
    {
      id: 2,
      step: "STEP 2",
      imgSrc: doc,
      altText: "profile",
      title: "DOCUMENTATION",
      bar: line,
      description:
        "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur",
    },
    {
      id: 3,
      step: "STEP 3",
      imgSrc: app,
      altText: "note",
      title: "Application process",
      bar: line,
      description:
        "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur",
    },
    {
      id: 4,
      step: "STEP 4",
      imgSrc: adm,
      altText: "plus",
      title: "Admission Offer",
      bar: line,
      description:
        "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur",
    },
    {
      id: 5,
      step: "STEP 5",
      imgSrc: letter,
      altText: "capture",
      title: "Offer letter acceptance",
      bar: line,
      description:
        "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur",
    },
    {
      id: 6,
      step: "STEP 6",
      imgSrc: visa,
      altText: "chat",
      title: "visa process",
      bar: line,
      description:
        "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur",
    },
  ];
  const Cards = ({ step, imgSrc, altText, title, bar, description }) => (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "auto",
          height: "auto",
          bgcolor: "#F4F4F4",
          borderRadius: "20px",
          boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.25)",
          margin: "10px",
          textAlign: "center",
          padding: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Typography className="card-step">{step}</Typography>
          <img src={imgSrc} alt={altText} sx={{ width: "69px" }} />
          <img src={line} alt={altText} />
          <Typography className="card-title">{title}</Typography>
          <Typography className="card-description">{description}</Typography>
        </Box>
      </Box>
    </>
  );
  return (
    <>
      <Grid container spacing={3}>
        {data.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Cards
              step={item.step}
              imgSrc={item.imgSrc}
              altText={item.altText}
              title={item.title}
              bar={item.bar}
              description={item.description}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default HomeCards;
