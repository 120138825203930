import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import plane from "../assets/plane.svg";
import uni from "../assets/uni.svg";
import book from "../assets/book.svg";
import cap from "../assets/cap.svg";
import left from "../assets/left.svg";
import right from "../assets/right.svg";
import "./ConsultCarousel.css";

const ConsultCarousel = () => {
  const [current, setCurrent] = useState(0);
  const Data = [
    {
      id: 1,
      image: plane,
      title: "Visa Assistance",
      alttext: "img",
    },
    {
      id: 2,
      image: uni,
      title: "Apply to Top Universities",
      alttext: "img",
    },
    {
      id: 3,
      image: cap,
      title: "Get Expert Grade & Guidance",
      alttext: "img",
    },
    {
      id: 4,
      image: book,
      title: "Enroll for Scholarships",
      alttext: "img",
    },
  ];

  const nextSlide = () => {
    setCurrent(current === Data.length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? Data.length - 1 : current - 1);
  };
  return (
    <>
      <Box position="absolute" zIndex={1}>
        {Data.map(
          (item, index) =>
            index === current && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="carousel-box"
                key={index}
              >
                <Box
                  sx={{ flex: 1, display: "flex", justifyContent: "center" }}
                >
                  <img src={left} onClick={prevSlide} alt="" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={item.image} alt={item.alttext} />
                  <Typography className="carousel-text">
                    {item.title}
                  </Typography>
                </Box>
                <Box
                  sx={{ flex: 1, display: "flex", justifyContent: "center" }}
                >
                  <img src={right} onClick={nextSlide} alt="" />
                </Box>
              </Box>
            )
        )}
      </Box>
      <Box position="absolute" className="carousel-back"></Box>
    </>
  );
};

export default ConsultCarousel;
