import React from "react";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import hero from "../assets/signup/hero.svg";
import line from "../assets/signin/line.svg";
import google from "../assets/signin/google.svg";
import fb from "../assets/signin/fb.svg";
import twitter from "../assets/signin/twitter.svg";
import "../index.css";

const SignUpPage = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",

          width: "100%",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            maxWidth: "500px",
          }}
        >
          {" "}
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img
              src={hero}
              alt="hero"
              style={{ width: "100%", height: "auto" }}
            />{" "}
          </Box>
        </Box>
        <Box
          sx={{
            // flex: 1,
            display: "flex",
            justifyContent: "center",
            width: { xs: "100%", md: "50%" },
          }}
        >
          <Paper
            elevation={5}
            sx={{
              padding: "2rem",
              width: "70%",
              marginY: "4rem",
              borderRadius: "2rem",
              height: "auto",
              marginLeft: { md: "10rem" },
            }}
          >
            <Typography
              className="custom-head"
              sx={{
                fontSize: {
                  md: "1.5rem !important",
                  textAlign: "center",
                  marginBottom: "2rem",
                },
              }}
            >
              Registeration
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography className="custom-text">First Name</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter your name"
                    type="text"
                    className="form-fields"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography className="custom-text">Last Name</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter your name"
                    type="text"
                    className="form-fields"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography className="custom-text">Email Id</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter your email"
                    type="text"
                    className="form-fields"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography className="custom-text">Phone No.</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter your number"
                    type="text"
                    className="form-fields"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography className="custom-text">Password</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter your number"
                    type="password"
                    className="form-fields"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography className="custom-text">
                    Confirm Password
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter your number"
                    type="text"
                    className="form-fields"
                  />
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                variant="contained"
                size="large"
                className="signin-button"
                sx={{
                  fontFamily: "RocknRoll One",
                  marginX: "0 auto",
                  textTransform: "uppercase",
                }}
              >
                Register
              </Button>
            </Box>{" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                marginY: "1rem",
              }}
            >
              <img src={line} alt="line" />

              <Typography
                className="custom-head"
                sx={{
                  fontSize: {
                    md: "1rem !important",
                    xs: "1rem !important",
                    textAlign: "center",
                  },
                }}
              >
                OR
              </Typography>
              <img src={line} alt="line" />
            </Box>
            <Typography
              className="custom-head"
              sx={{
                fontSize: {
                  md: "1rem !important",
                  xs: "1rem !important",
                  textAlign: "center",
                },
              }}
            >
              Login with
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                gap: "2px",
                marginTop: "1rem",
              }}
            >
              {" "}
              <img src={google} alt="google" /> <img src={fb} alt="fb" />{" "}
              <img src={twitter} alt="twitter" />
            </Box>
            <Typography
              className="custom-head"
              sx={{
                fontSize: {
                  md: "1rem !important",
                  xs: "1rem !important",
                  textAlign: "center",
                },
                marginTop: "0.2rem",
              }}
            >
              Already have an account? Sign In
            </Typography>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default SignUpPage;
