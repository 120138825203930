import React from "react";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import hero from "../assets/signin/hero.svg";
import line from "../assets/signin/line.svg";
import google from "../assets/signin/google.svg";
import fb from "../assets/signin/fb.svg";
import twitter from "../assets/signin/twitter.svg";
import "../index.css";

const SignInPage = () => {
  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",

            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: { xs: "100%", md: "50%" },
            }}
          >
            <Paper
              elevation={5}
              sx={{
                background: "#03008A",
                padding: "2rem",
                width: "70%",
                marginY: "4rem",
                borderRadius: "2rem",
              }}
            >
              <Typography
                className="custom-head"
                sx={{
                  fontSize: {
                    md: "1.5rem !important",
                    textAlign: "center",
                    color: "#fff",
                    marginBottom: "2rem",
                  },
                }}
              >
                Sign In
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <Typography className="custom-text" sx={{ color: "#fff" }}>
                      Email id
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Enter Your email"
                      type="text"
                      className="form-fields"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Typography className="custom-text" sx={{ color: "#fff" }}>
                      Password
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Enter Password"
                      type="text"
                      className="form-fields"
                    />
                    <Typography
                      className="custom-head"
                      sx={{
                        fontSize: {
                          md: "1rem !important",
                          xs: "1rem !important",
                          textAlign: "right",
                          color: "#fff",
                        },
                        marginTop: "0.2rem",
                      }}
                    >
                      Forget Password ?
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <Button
                  variant="outlined"
                  size="large"
                  className="signin-button"
                  sx={{ fontFamily: "RocknRoll One", marginX: "0 auto" }}
                >
                  Sign In
                </Button>
              </Box>{" "}
              <Typography
                className="custom-head"
                sx={{
                  fontSize: {
                    md: "1rem !important",
                    xs: "1rem !important",
                    textAlign: "center",
                    color: "#fff",
                  },
                  marginTop: "0.5rem",
                }}
              >
                Don’t have an account? Register
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginY: "1rem",
                }}
              >
                <img src={line} alt="line" />

                <Typography
                  className="custom-head"
                  sx={{
                    fontSize: {
                      md: "1rem !important",
                      xs: "1rem !important",
                      textAlign: "center",
                      color: "#fff",
                    },
                  }}
                >
                  OR
                </Typography>
                <img src={line} alt="line" />
              </Box>
              <Typography
                className="custom-head"
                sx={{
                  fontSize: {
                    md: "1rem !important",
                    xs: "1rem !important",
                    textAlign: "center",
                    color: "#fff",
                  },
                }}
              >
                Login with
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  gap: "2px",
                  marginTop: "1rem",
                }}
              >
                {" "}
                <img src={google} alt="google" /> <img src={fb} alt="fb" />{" "}
                <img src={twitter} alt="twitter" />
              </Box>
            </Paper>
          </Box>

          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%", // Ensure the Box takes full width
              maxWidth: "477px", // Limit max width
            }}
          >
            {" "}
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <img
                src={hero}
                alt="hero"
                style={{ width: "100%", height: "auto" }}
              />{" "}
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default SignInPage;
