import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import usa from "../../assets/usa.png";
import uk from "../../assets/uk.png";
import canada from "../../assets/canada.png";
import australia from "../../assets/australia.png";
import newzealand from "../../assets/newzealand.png";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Destination.css";
import "../../index.css";

const Destination = () => {
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <Typography
            sx={{
              color: "primary.main",
            }}
            className="custom-head"
          >
            Explore the best study{" "}
            <Typography
              component="span"
              sx={{
                color: "secondary.main",
              }}
              className="custom-head"
            >
              Destinations{" "}
            </Typography>
            in the world!
          </Typography>
        </Box>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            // clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
        >
          <SwiperSlide>
            <Box className="destination-box">
              <img src={usa} alt="usa" className="destination-image" />
              <Box className="destination-content">
                <Typography className="dest-head">Study in USA</Typography>
                <Typography className="dest-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut orem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut
                </Typography>
                <Button variant="outlined" className="dest-button">
                  view details
                </Button>
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box className="destination-box">
              <img src={canada} alt="canada" className="destination-image" />
              <Box className="destination-content">
                <Typography className="dest-head">Study in Canada</Typography>
                <Typography className="dest-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut orem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut
                </Typography>
                <Button variant="outlined" className="dest-button">
                  view details
                </Button>
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box className="destination-box">
              <img
                src={australia}
                alt="australia"
                className="destination-image"
              />
              <Box className="destination-content">
                <Typography className="dest-head">
                  Study in Australia
                </Typography>
                <Typography className="dest-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut orem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut
                </Typography>
                <Button variant="outlined" className="dest-button">
                  view details
                </Button>
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box className="destination-box">
              <img src={uk} alt="uk" className="destination-image" />
              <Box className="destination-content">
                <Typography className="dest-head">Study in UK</Typography>
                <Typography className="dest-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut orem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut
                </Typography>
                <Button variant="outlined" className="dest-button">
                  view details
                </Button>
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box className="destination-box">
              <img
                src={newzealand}
                alt="newzealand"
                className="destination-image"
              />
              <Box className="destination-content">
                <Typography className="dest-head">
                  Study in NewZealand
                </Typography>
                <Typography className="dest-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut orem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut
                </Typography>
                <Button variant="outlined" className="dest-button">
                  view details
                </Button>
              </Box>
            </Box>
          </SwiperSlide>
          <Box className="slider-control">
            <Box className="swiper-button-prev slider-arrow">
              {/* <ion-icon name="arrow-round-back"></ion-icon> */}
            </Box>
            <Box className="swiper-button-next slider-arrow">
              {/* <ion-icon name="arrow-round-forward"></ion-icon> */}
            </Box>
            <Box className="swiper-pagination"></Box>
          </Box>
        </Swiper>
      </Box>
    </>
  );
};

export default Destination;
