import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import logo from "../../assets/logo.svg";
import web from "../../assets/web.svg";
import pin from "../../assets/pin.svg";
import phone from "../../assets/phone.svg";
import mail from "../../assets/mail.svg";
import fb from "../../assets/fb.svg";
import twitter from "../../assets/twitter.svg";
import insta from "../../assets/insta.svg";
import linked from "../../assets/linked.svg";
import map from "../../assets/map.svg"; // Path to your background image

import "./Footer.css";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${map})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        py: 4,
      }}
    >
      <Container>
        <footer>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography className="footer-heads">
                Want to Know More
              </Typography>
              <Typography
                sx={{
                  color: "#6F6F6F",
                  fontFamily: "Philosopher",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "28px",
                }}
              >
                Contact us today for personalised assistance and expert advice.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                }}
              >
                <TextField
                  variant="outlined"
                  placeholder="Enter your email address"
                  type="text"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      color: "#8A8A8A",
                      fontFamily: "Philosopher",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight:
                        "var(--sds-typography-body-font-weight-regular)",
                      lineHeight: "100%",
                      borderRadius: "8px",
                      border: "var(--sds-size-stroke-border) solid #858585",
                      opacity: "var(--sds-size-stroke-border)",
                      background: "var(--sds-color-background-default-default)",
                      display: "flex",
                      padding:
                        "var(--sds-size-space-300) var(--sds-size-space-400)",
                      justifyContent: "center",
                      alignItems: "center",
                      bgcolor: "#fff",
                    },
                    flex: 3,
                  }}
                />
                <Button
                  sx={{ flex: 1 }}
                  variant="contained"
                  size="small"
                  className="newsletter-button"
                >
                  Subscribe
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              borderBottom: 1,
              borderTop: 1,
              borderColor: "#BDBDBD",
              marginY: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Grid
              item
              xs={6}
              sm={3}
              md={3}
              lg={3}
              sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}
              // 6,4,4,3
            >
              <img src={logo} alt="" width={"60%"} />
              <Typography className="footer-listItems">
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores et quas molestias excepturi sint occaecati cupiditate
                non provident, similique s
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3} sx={{ p: 2 }}>
              <Typography sx={{ paddingLeft: 2 }} className="footer-heads">
                Product
              </Typography>
              <List sx={{ listStyleType: "none" }} className="footer-listItems">
                <ListItem>Services</ListItem>
                <ListItem>Study Visa </ListItem>
                <ListItem>Study Abroad</ListItem>
                <ListItem>Career</ListItem>
                <ListItem>Success Stories</ListItem>
              </List>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3} sx={{ p: 2 }}>
              <Typography sx={{ paddingLeft: 2 }} className="footer-heads">
                Quick Links
              </Typography>
              <List
                sx={{ listStyleType: "none", paddingLeft: 0 }}
                className="footer-listItems"
              >
                <ListItem>Home </ListItem>
                <ListItem>About</ListItem>
                <ListItem>Who we are</ListItem>
                <ListItem>Tourist Visa</ListItem>
                <ListItem>Career</ListItem>
              </List>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3} sx={{ p: 2 }}>
              <Typography className="footer-heads">Contact</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  paddingTop: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <img src={phone} alt="" />
                  <Typography className="footer-listItems">
                    +91 8764636543
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <img src={mail} alt="" />
                  <Typography className="footer-listItems">
                    Unizquest@gmail
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <img src={web} alt="" />
                  <Typography className="footer-listItems">
                    www.unizquest.in
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <img src={pin} alt="" />
                  <Typography className="footer-listItems">
                    SCO-10, Sector -42, Haryana, chd
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 2,
              "@media (max-width: 600px)": {
                flexDirection: "column-reverse",
              },
            }}
          >
            <Box
              sx={{ flex: 1, display: "flex", justifyContent: "center", p: 2 }}
            >
              <Typography>© 2024 Cadet UI. All Rights Reserved.</Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                p: 2,
              }}
            >
              <Typography className="footer-listItems">English</Typography>
              <Typography className="footer-listItems">Privacy</Typography>
              <Typography className="footer-listItems">Legal</Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                p: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: 3,
              }}
            >
              <img src={fb} alt="fb" />
              <img src={twitter} alt="twitter" />
              <img src={insta} alt="insta" />
              <img src={linked} alt="linked" />
            </Box>
          </Box>
        </footer>
      </Container>
    </Box>
  );
};

export default Footer;
