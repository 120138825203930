import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import "./FloatingButton.css";
import Appointment from "./Appointment";

const FloatButton = styled(Button)({
  position: "fixed",
  top: "50%",
  left: "100%",
  transform: "translate(-50%, -50%) rotate(90deg)",
  zIndex: 1000,
  rotate: 90,
  width: "max-content",
});

const FloatingButton = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  return (
    <Box>
      <FloatButton
        onClick={handleOpenPopup}
        variant="contained"
        color="primary"
        className="float-button"
      >
        <br />
        Book An Appointment
      </FloatButton>
      <Appointment isOpen={isPopupOpen} onClose={handleClosePopup} />
    </Box>
  );
};

export default FloatingButton;
