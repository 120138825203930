import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Aboutus from "./pages/Aboutus";
import Home from "./pages/Home";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Contact from "./pages/Contact";
import FloatingButton from "./Components/Appointment/FloatingButton";
import Blog from "./pages/Blog";
import VissionAndMission from "./pages/VissionAndMission";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import SuccessStories from "./pages/SuccessStories";

function App() {
  return (
    <BrowserRouter>
      <Navbar /> {/* Navbar component rendered across all routes */}
      <FloatingButton />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/vision-mission" element={<VissionAndMission />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/success-stories" element={<SuccessStories />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
