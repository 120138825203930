import React from "react";
import { Box, Container, Typography } from "@mui/material";
import styled, { keyframes, css } from "styled-components";
import acadia from "../../assets/aboutus/acadia.png";
import cambrian from "../../assets/aboutus/cambrian.png";
import northern from "../../assets/aboutus/northern.png";
import queen from "../../assets/aboutus/queen.png";
import regina from "../../assets/aboutus/regina.png";
import canada from "../../assets/aboutus/canada.png";
import "../../index.css";

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Marquee = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const scrollX = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  display: flex;
  align-items: center;
  white-space: nowrap;
  animation: ${scrollX} 100s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}
  flex: 1;
  display: flex;
  width: fit-content;
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
  width: clamp(5rem, 1rem + 20vmin, 15rem);
  padding: calc(clamp(5rem, 1rem + 20vmin, 15rem) / 15);
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%
  aspect-ratio: 16/9;
`;

const Featured = () => {
  const data = [acadia, cambrian, northern, queen, regina, canada];

  // Duplicate the data for continuous scrolling effect
  const extendedData = [
    ...data,
    ...data,
    ...data,
    ...data,
    ...data,
    ...data,
    ...data,
    ...data,
  ];

  return (
    <Container>
      <Wrapper>
        <Marquee>
          <MarqueeGroup>
            {extendedData.map((el, index) => (
              <ImageGroup key={index}>
                <Image src={el} alt={`Accreditation ${index + 1}`} />
              </ImageGroup>
            ))}
          </MarqueeGroup>
        </Marquee>
      </Wrapper>
    </Container>
  );
};

export default Featured;
