import React from "react";
import "../index.css";
import { Box, Grid, Typography } from "@mui/material";

const Experience = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginY: "70px",
        }}
      >
        <Typography
          sx={{
            color: "primary.main",
          }}
          className="custom-head"
        >
          Using our{" "}
          <Typography
            component="span"
            sx={{
              color: "secondary.main",
            }}
            className="custom-head"
          >
            Experience{" "}
          </Typography>
          to create Opportunities for you
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={3}>
          <Box className="experience">
            <Typography className="exp-text">
              3K <br />
              Universities
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box className="experience">
            <Typography className="exp-text">
              20,000+ <br />
              Students <br />
              Placed
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box className="experience">
            <Typography className="exp-text">
              40+ <br />
              Countries
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box className="experience">
            <Typography className="exp-text">
              300+ <br />
              Partner
              <br /> Institutions
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Experience;
