import { Box, Container, Typography } from "@mui/material";
import React from "react";
import header from "../assets/Blog/blog.svg";
import group from "../assets/Blog/group.png";
import fb from "../assets/Blog/fb.svg";
import twitter from "../assets/Blog/twitter.svg";
import insta from "../assets/Blog/insta.svg";
import linked from "../assets/Blog/linkedin.svg";
import "../index.css";

const Blog = () => {
  return (
    <>
      <Container>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
        >
          {" "}
          <img
            src={header}
            alt="header"
            style={{ width: "50%", height: "auto" }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          // height={{ md: "20rem" }}
          marginTop="2rem"
        >
          <Box
            // bgcolor="lightblue"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                // marginLeft: { md: "4rem" },
                position: "relative",
                width: { md: "500px" },
              }}
            >
              <img
                src={group}
                alt="group"
                className="custom-blog-image"
                sx={{}}
              />
            </Box>
          </Box>
          <Box
            sx={{
              marginLeft: { md: "-3rem" },
              padding: "2rem",
            }}
            bgcolor="#EBF0FF"
            // className="cornerBorderBox"
          >
            <Box
              sx={{
                gap: "1rem",
                display: "flex",
                flexDirection: "column",
                paddingLeft: { md: "3rem" },
              }}
            >
              <Typography
                sx={{
                  color: "primary.main",
                }}
                className="custom-head"
              >
                Best countries for students to study Abroad
              </Typography>
              <Typography className="custom-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <Typography
                  sx={{
                    color: "primary.main",
                  }}
                  className="custom-text"
                >
                  Study In Australia{" "}
                  <Typography component="span" className="custom-text">
                    | 20 July 2024{" "}
                  </Typography>
                </Typography>
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <img src={fb} alt="fb" />
                  <img src={insta} alt="insta" />
                  <img src={twitter} alt="twitter" />
                  <img src={linked} alt="linked" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      textDecoration: "underline",
                      display: "flex",
                      justifyContent: "end",
                    }}
                    className="custom-text"
                  >
                    Read More
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: "column-reverse", md: "row" }}
          // height={{ md: "20rem" }}
          marginTop="2rem"
        >
          <Box
            sx={{
              marginRight: { md: "-3rem" },
              padding: "2rem",
            }}
            bgcolor="#EBF0FF"
          >
            <Box
              sx={{
                gap: "1rem",
                display: "flex",
                flexDirection: "column",
                paddingRight: { md: "3rem" },
              }}
            >
              <Typography
                sx={{
                  color: "primary.main",
                }}
                className="custom-head"
              >
                Best countries for students to study Abroad
              </Typography>
              <Typography className="custom-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <Typography
                  sx={{
                    color: "primary.main",
                  }}
                  className="custom-text"
                >
                  Study In Australia{" "}
                  <Typography component="span" className="custom-text">
                    | 20 July 2024{" "}
                  </Typography>
                </Typography>
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <img src={fb} alt="fb" />
                  <img src={insta} alt="insta" />
                  <img src={twitter} alt="twitter" />
                  <img src={linked} alt="linked" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      textDecoration: "underline",
                      display: "flex",
                      justifyContent: "end",
                    }}
                    className="custom-text"
                  >
                    Read More
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            // bgcolor="lightblue"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                // marginLeft: { md: "4rem" },
                position: "relative",
                width: { md: "500px" },
              }}
            >
              <img
                src={group}
                alt="group"
                className="custom-blog-image"
                sx={{}}
              />
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          // height={{ md: "20rem" }}
          marginTop="2rem"
        >
          <Box
            // bgcolor="lightblue"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                // marginLeft: { md: "4rem" },
                position: "relative",
                width: { md: "500px" },
              }}
            >
              <img
                src={group}
                alt="group"
                className="custom-blog-image"
                sx={{}}
              />
            </Box>
          </Box>
          <Box
            sx={{
              marginLeft: { md: "-3rem" },
              padding: "2rem",
            }}
            bgcolor="#EBF0FF"
          >
            <Box
              sx={{
                gap: "1rem",
                display: "flex",
                flexDirection: "column",
                paddingLeft: { md: "3rem" },
              }}
            >
              <Typography
                sx={{
                  color: "primary.main",
                }}
                className="custom-head"
              >
                Best countries for students to study Abroad
              </Typography>
              <Typography className="custom-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <Typography
                  sx={{
                    color: "primary.main",
                  }}
                  className="custom-text"
                >
                  Study In Australia{" "}
                  <Typography component="span" className="custom-text">
                    | 20 July 2024{" "}
                  </Typography>
                </Typography>
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <img src={fb} alt="fb" />
                  <img src={insta} alt="insta" />
                  <img src={twitter} alt="twitter" />
                  <img src={linked} alt="linked" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      textDecoration: "underline",
                      display: "flex",
                      justifyContent: "end",
                    }}
                    className="custom-text"
                  >
                    Read More
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: "column-reverse", md: "row" }}
          // height={{ md: "20rem" }}
          marginTop="2rem"
        >
          <Box
            sx={{
              marginRight: { md: "-3rem" },
              padding: "2rem",
            }}
            bgcolor="#EBF0FF"
          >
            <Box
              sx={{
                gap: "1rem",
                display: "flex",
                flexDirection: "column",
                paddingRight: { md: "3rem" },
              }}
            >
              <Typography
                sx={{
                  color: "primary.main",
                }}
                className="custom-head"
              >
                Best countries for students to study Abroad
              </Typography>
              <Typography className="custom-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <Typography
                  sx={{
                    color: "primary.main",
                  }}
                  className="custom-text"
                >
                  Study In Australia{" "}
                  <Typography component="span" className="custom-text">
                    | 20 July 2024{" "}
                  </Typography>
                </Typography>
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <img src={fb} alt="fb" />
                  <img src={insta} alt="insta" />
                  <img src={twitter} alt="twitter" />
                  <img src={linked} alt="linked" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      textDecoration: "underline",
                      display: "flex",
                      justifyContent: "end",
                    }}
                    className="custom-text"
                  >
                    Read More
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            // bgcolor="lightblue"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                // marginLeft: { md: "4rem" },
                position: "relative",
                width: { md: "500px" },
              }}
            >
              <img
                src={group}
                alt="group"
                className="custom-blog-image"
                sx={{}}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Blog;
