import React from "react";
import { Box, Container, Typography } from "@mui/material";
import styled, { keyframes, css } from "styled-components";
import line from "../../assets/line.svg";
import uniagents from "../../assets/uniagents.png";
import icef from "../../assets/icef.png";
import idp from "../../assets/idp.png";
import qeac from "../../assets/qeac.png";
import net from "../../assets/net.png";
import capic from "../../assets/capic.png";
import "../../index.css";

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Marquee = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const scrollX = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  display: flex;
  align-items: center;
  white-space: nowrap;
  animation: ${scrollX} 50s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}
  flex: 1;
  display: flex;
  width: fit-content;
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
  width: clamp(5rem, 1rem + 20vmin, 15rem);
  padding: calc(clamp(5rem, 1rem + 20vmin, 15rem) / 15);
`;

const Image = styled.img`
  object-fit: contain;
  width: 50%;
  height: 100%
  aspect-ratio: 16/9;
`;

const Accrediation = () => {
  const data = [uniagents, icef, idp, qeac, net, capic];

  // Duplicate the data for continuous scrolling effect
  const extendedData = [...data, ...data, ...data, ...data];

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: { md: "space-around", xs: "center" },
          alignItems: "center",
          marginY: "70px",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <img src={line} alt="Decorative line" />
        <Typography
          sx={{
            color: "primary.main",
          }}
          className="custom-head"
        >
          Global Accreditations & Recognition
        </Typography>
        <img src={line} alt="Decorative line" />
      </Box>
      <Wrapper>
        <Marquee>
          <MarqueeGroup>
            {extendedData.map((el, index) => (
              <ImageGroup key={index}>
                <Image src={el} alt={`Accreditation ${index + 1}`} />
              </ImageGroup>
            ))}
          </MarqueeGroup>
        </Marquee>
      </Wrapper>
    </Container>
  );
};

export default Accrediation;
